.simplebar-content {
  /* display: contents !important */
}

.auth-wrap {
  margin: 20px;
}

.main-container-app {
  background-color: black !important;
  background-image: url('../../assets/icon/Vector.png') !important ;
  background-repeat: no-repeat;
  background-position: top 0px left -10px;
  background-size: 44%;
}

.sign-btn {
  width: 100% !important;
  background-color: black !important;
  border: none;
  margin-top: 30px;
}

.ant-card {
  max-width: 50% !important;
  border-radius: 8.29604px !important;
  padding: 30px 50px 10px 50px;
}

.ant-card-body {
  display: contents;
}

.auth-main-content {
  width: 100% !important;
}

.loginDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loginDetails>p {
  color: #878787 ;
  margin-bottom: 20px;
}

.login-privacy-text{
  margin-bottom: 5px !important;
}



.registerDetails {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 15px;
}

.registerDetails >p{
  color: gray;
}

.registerDetails >a{
  color: #6966C6;
  margin-left: 10px;
}

.HeadingAuth {
  margin-bottom: 10px;
}
.termOfPrivacy {
  display: flex;
  justify-content: space-around;
}

.termOfPrivacy >p{
 color: #9A98D7 ;
 margin-right: 4px;
}


@media screen and (max-width: 768px) {
  .ant-card {
    max-width: 100vh !important;
    padding: 0px;
  }
  .main-container-app {
    background-size: 0% !important;
  }
}
@media (min-width: 1250px) {
  .ant-card {
    max-width: 35% !important;
  }
}

@media (max-width: 1024px) {
  .main-container-app {
    background-position: top 0px left -150px;
    background-size: 70%;
  }
}
