.Avator_logoutBtn {
  /* margin: 280px 0px 100px 0px; */
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 20px;
  padding: 0px 30px;
}

.sideMenu{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}

.avator_sub_container {
  display: flex;
  margin-bottom: 10px;
  cursor:default;
  justify-content: space-between;
  /* align-items: center; */
}
.Actor_name {
  line-height: 5px;
  margin-top: 10px;
  height: fit-content;
  width:72%;
}

.Actor_name>h5 {
  color: white;
}

.flexFont{
  font-size: 11px;
  color: #6f7782;
  line-height: 1.2em;
  height:2em;
  width:100%;
  color: #ffffff; 
  /* background-color:#eeeeee; */
  padding:1%;
  /* margin: 10px; */
}
.flexFont>p{
  font-size: 11px;
  color: #8d8d8d;
  font-variant-ligatures:discretionary-ligatures;
  padding: 0;
  margin: 0;
  /* overflow-wrap: break-word; */
}
.logOutBtn {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #ffffff64;
  color: #b5b5b5b1;
  width: 10.5rem;
  height: 2.4rem;
  padding: 7px;
  font: 600;
  cursor: pointer;
}


.logOutBtn:hover {
  background-color: rgb(0, 0, 0);
  transition: all 0.3s ease-in;
}

.profileButton{
  display: flex;
  border: none;
  color: #ffffff;
  cursor: pointer;
  background-color:#161b25;
  justify-content: baseline;
  margin-left: 30px;
}

.loginButtonHover{
    color:#ffffff;
    font-size:20px;
    margin-right:10px;
    margin-top: 2px;
}

.loginButtonHover:hover{
  color: rgb(202, 202, 202);
}

.logo {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
}
.app-header-mini-sidebar .trigger {
  font-size: 22px;
  color: #8f8f8f;
  margin-left: -15px !important;
}

/* header.css */

.app-header-mini-sidebar {
  height: 0px;
}

.trigger {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .app-header-mini-sidebar .trigger {
    margin-left: 0px !important;
    margin-top: 56px !important;
  }
}
@media screen and (max-width: 1200px) {
  .app-header-mini-sidebar .trigger {
    margin-left: -10px !important;
    margin-top: 56px !important;
  }
}

@media screen and (min-height:900px) {
  .Avator_logoutBtn {
    /* margin: 400px 0px 100px 0px; */
    padding: 0px 30px;
    vertical-align: bottom;
  }
 
.isCollapsedTrue{
  padding: 0px 12px;
  margin-bottom: 30px;
}
}


.isCollapsedTrue{
  padding: 0px 12px;
  margin-bottom: 40px;
}

/* logo.css */

.logo-image {
  margin: 35px 20px 50px 15px;
  width: 30px;
  height: 40px;
}

.logo-container {
  margin: 35px 40px 0px 15px;
  width: auto;
  height: 60px;
}

/* logout.css */

.logout-container {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.logout-icon {
  width: 18px;
  padding-top: 10px;
}