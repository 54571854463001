.ant-table-thead > tr > th {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}
.ant-form-item-explain-error {
  padding: 2px 0px;
}
.ant-table-column-sorter {
  color: black;
}

.case-action-button-container {
  display: flex;
  justify-content: end;
  align-items: center;
}

.navigationButton-alignment {
  padding: 4px;
  margin-left: 5px;
}

.link-error-content {
  background: white;
  padding: 100px 50px;
  border-radius: 20px;
  margin: 20px;
}
.error-para > p {
  font-weight: 600;
  font-size: 24px;
}
.profile-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.profile-form-item {
  padding: 10px;
  width: 100%;
  margin-bottom: 0px !important;
}

.color-search-icon {
  color: #1890ff;
  font-size: 15px;
}

._color-search-icon {
  color: undefined;
  font-size: 15px;
}

.search-container {
  padding: 8px;
  border: none !important;
}

.check-tr {
  color: green;
}

.check-fl {
  color: red;
}

.checkbox-custom {
  margin: 0 !important;
}

.custom-side-bar {
  margin-left: 15px;
  transition: all 0.4s ease-in-out;
}

.action-button-modified-role {
  color: white;
}

.side-bar-border {
  border: '1px solid #f0f0f0';
}

.custom-m {
  margin-top: 20px;
}

.custom-mr {
  margin-right: 10px;
}

.global-badge-style {
  margin: 0px 8px 12px 2px;
}

.global-layout {
  background: white;
}

.side-bar-icon {
  font-size: 22px;
}

.myFlexContainer {
  display: flex;
  align-items: center;
}
.myFlexContainer-S_b {
  display: flex;
  justify-content: space-between;
}

.form-item-divider {
  margin: 10px 0;
}

.max-container-width {
  max-height: 400px;
}

.input-custom-width {
  width: 100%;
  cursor: pointer;
}

.header-common-divider {
  margin-top: 18px;
}

.common-auth-button {
  background: black !important;
  border: none !important;
}
.userAddOutlined {
  color: gray !important;
  cursor: pointer;
}

.global-common-button {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 15px;
  width: auto;
  align-items: center;
  height: auto;
  font-weight: 500;
  color: white;
  background-color: black;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.dynamic-width {
  width: auto;
}

.input-space-container {
  padding: 2px 15px;
  padding-bottom: 0px;
}
/* .ant-table-content{
  overflow: auto !important;
  min-height: 200px;
} */

.input-style {
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
}
@media print {
  .mini-sidebar-toggle.ant-layout-sider-collapsed
    + .app-layout-mini-sidebar-main {
    margin-left: 0px !important;
  }
  .mainBusinessCaseContainer {
    margin: 0px !important;
  }
}

.ant-table-filter-trigger {
  color: black;
}

.marketTextStyle {
  font-weight: 600;
  font-size: 15px;
  background: none;
  padding: 3px;
  display: flex;
  justify-content: center;
}

.marketText {
  width: 100%;
  padding: 3px;
  font-weight: 400;
  font-size: 14px;
  background: none;
  display: flex;
  /* color: #6d6e6f; */
}

.comp {
  display: flex;
  width: auto;
  justify-content: start;
  align-items: center;
  margin: 4px;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.image_container {
  margin: 20px 0px 20px 40px;
}

.CompanySetting {
  margin: 10px 20px 30px 20px;
}

.table_container {
  flex: 0.7 1;
  width: 60%;
}

.back_btn {
  width: 5.4rem;
  height: 2.3rem;
  padding-left: 0px;
  padding-right: 5px;
  background: #6966c6;
  border-radius: 4px;
  border: 2.25333px solid #ffffff;
  margin-left: 10px;
  margin-bottom: 15px;
  color: white;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.infobutton {
  border: none;
  background: transparent;
  cursor: pointer;
}

.infobuttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ResendExpirelink {
  border: none;
  padding: 4px 8px;
  float: right;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1ms ease-in-out;
}

.ResendExpirelink:hover {
  background-color: lightgreen;
  transition: all 0.1ms ease-in;
}

.deviceContainer {
  margin-top: 19px;
}

.widthEva {
  width: 100%;
}

.addinnerrow {
  display: flex;
  width: 10rem;
  height: auto;
  font-size: 12px;
  border-radius: 3px;
  color: #60646a;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.company-layout {
  padding: 75px 60px 10px 60px;
  background: white;
}

.add {
  width: auto;
  height: auto;
  background-color: black;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  padding-left: 4.5px;
  padding-right: 4.5px;
  cursor: pointer;
}

.icon-placement {
  float: right;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: rgba(0, 0, 0, 0.507);
  font-size: 25px;
  margin-left: 24px;
  margin-right: 30px;
  z-index: 999;
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  right: -27px;
}

.accessTable {
  margin-top: 66px;
}

.ant-modal-body {
  padding: 0px !important;
}

.tableHeaderText {
  display: flex;
  justify-content: center;
  font-size: 20px;
  background-color: #0000000d;
  padding: 10px;
  margin-top: 5px;
}

.companyAddresscard {
  display: flex;
  justify-content: space-between;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}

.status-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.mobile-Edit-button {
  margin-left: 8px;
  padding: 6px 10px;
  border-radius: 3px;
  border: none;
  color: rgb(0, 0, 0);
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.Edit-caseStaus-button:hover {
  border: 1px solid #6966c6;
}

.Edit-caseStaus-button {
  float: right;
  margin-left: 13px;
  padding: 6px 10px;
  border-radius: 3px;
  border: none;
  color: rgb(0, 0, 0);
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.addresscontainer {
  width: 44vw;
}

.common-flex {
  display: flex;
}

.registerCard {
  display: flex;
  flex-direction: row;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  min-width: 200px;
  max-width: 100%;
  margin-bottom: 20px;
}

.imageregisterCard {
  position: relative;
  height: 100px;
}

.imageregisterCard img {
  width: 100%;
  height: auto;
}

.contentregisterCard {
  flex: 1;
  padding: 20px;
}

.title {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
  line-height: 1.5;
}

.container {
  width: 99% !important;
  padding: 0px !important;
  position: relative;
}

.overlay {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50;
  right: 50;
  opacity: 0;
  transition: opacity 0.5s;
  color: black !important;
  background-color: rgba(255, 255, 255, 0.559);
}

.container:hover .overlay {
  opacity: 1;
}

.productImageIcon {
  color: #fff;
  display: flex;
  text-align: center;
  line-height: 200px;
  font-size: 24px;
  margin: 0px 30px;
  position: absolute;
  font-size: 17px;
}

.view {
  left: 5vw;
}

.delete {
  right: 5vw;
}

.productImageIcon:hover {
  cursor: pointer;
  color: rgb(255, 255, 255) !important;
}

.ant-menu-item ant-menu-item-only-child:hover {
  background-color: white !important;
}

.ant-layout .app-layout-mini-sidebar-main {
  background-color: white !important;
}

.layoutTableBackground {
  background-color: white;
}

.comp {
  font-weight: 400;
}

.navigationButton {
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: transparent;
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
  height: auto;
}

.navigationdisabled {
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: rgb(218, 218, 218) !important;
  border-radius: 4px;
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
  height: auto;
}

.specifiCationButton {
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  min-width: auto;
  max-width: 30%;
  background: transparent;
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
  height: auto;
}

.ant-alert-with-description {
  align-items: center;
}

.checked-box {
  margin-top: 20px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(0, 0, 0);
}

.container-heading {
  display: flex;
  justify-content: left;
}

.uploadContainer {
  width: 70% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  line-height: 25px;
  align-items: center;
  margin: 50px;
}

.breadcrumb-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px;
  z-index: 9999;
  position: fixed;
  background-color: rgb(255, 255, 255);
}

.backBtn {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(106 103 206 / 60%);
  padding: 6px;
  color: #6966c6;
  border-radius: 4px;
  border: none;
  margin-top: -7px;
  margin-right: 10px;
  cursor: pointer;
}

.ExectinoStrategySwitchbtn {
  margin-left: 20px;
  font-weight: 600;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.Company_name_Heading {
  font-weight: 600;
  font-size: 23px;
  line-height: 10px;
  align-items: center;
  color: #6966c6;
  margin-top: -10px;
  margin-left: 0px;
  margin-bottom: 0px;
  width: 100%;
  line-height: unset;
}

.WhatCustomerWants_container {
  margin-top: 15px;
}

.market_container {
  margin-top: 15px;
}

.editIConstyle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
}

.ant-form-item-row {
  flex-direction: column !important;
}

.ant-form-item-label > label {
  font-weight: 500;
  float: left !important;
}

.ant-form-horizontal > .ant-form-item {
  margin-bottom: 10px;
}

.colorType {
  display: flex;
  height: auto;
  width: auto;
  background: transparent;
  border-radius: 1px;
  font-weight: 400;
  font-size: 13px;
  padding: 5px 10px;
  white-space: normal;
  word-break: break-word;
  line-height: 16px;
  margin-right: 5px;
}

.colorTypeText {
  display: flex;
  font-weight: 400;
  font-size: 14px;
}

.colorTypeTxt {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
}
.custom-layout {
  background: white;
  /* Other styles */
}

@media screen and (max-width: 1200px) {
  .view {
    left: 2vw;
  }

  .delete {
    right: 2vw;
  }

  .breadcrumb-heading {
    padding: 20px 50px;
  }

  .addresscontainer {
    width: 100%;
  }
}

.ant-pagination-item-active {
  border: 1px solid #797979;
}

.ant-pagination-item-active a {
  color: rgb(131, 131, 131) !important;
}

@media screen and (max-width: 800px) {
  .table_container {
    width: 100%;
  }

  .company-layout {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 0px;
  }

  .accessTable {
    margin-top: 0px;
  }

  .view {
    left: 5vw;
  }

  .delete {
    right: 5vw;
  }

  .icon {
    margin: 0px 30px;
    font-size: 17px;
  }

  .Company_name_Heading {
    margin: -10px 0px 0px 5px;
    width: 100%;
    overflow-wrap: anywhere;
  }

  .breadcrumb-heading {
    padding: 20px 50px;
  }

  .addresscontainer {
    margin-top: 10px;
  }
}

.ExectinoStrategybtn {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 10px;
  border: 1.52941px solid #d8d8d8;
  border-radius: 6.11765px;
  background-color: transparent;
  color: #353535;
  font-weight: 700;
  font-size: 14.173px;
  margin-right: 10px;
  cursor: pointer;
}

.or {
  margin: 0px 10px;
}

.ExectinoStrategybtn:hover {
  border: 1.52941px solid #0a8fdc;
}

@media screen and (max-width: 573px) {
  .CompanySetting {
    margin: 15px 20px 30px 50px;
  }
  .report-mt {
    margin-top: 40px !important;
  }

  .deviceContainer {
    margin-top: 15px;
  }

  .accessTable {
    margin-top: 0px;
  }

  .popupImage {
    margin-left: 55px !important;
  }

  .mainBusinessCaseContainer {
    margin: 110px 35px 20px 35px !important;
    width: auto;
  }

  .mainBusinessCaseContainer-large {
    margin: 140px 35px 20px 35px !important;
    width: auto;
  }

  .masters {
    width: auto !important;
    margin-top: 30px !important;
    margin-right: 30px !important;
    margin-left: 50px !important;
  }

  .icon {
    margin: 0px 30px;
  }

  .or {
    display: flex;
    justify-content: center;
    margin: 8px 0px;
  }

  .ExectinoStrategybtn {
    width: 100%;
  }

  .ExectinoStrategySwitchbtn {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    justify-content: center;
    width: 92%;
    align-items: normal;
    margin: 10px 40px 10px 40px;
    font-weight: 600;
    margin-left: 0px;
  }

  .breadcrumb-heading {
    padding: 20px 50px;
    width: 100%;
  }

  .pageHeading {
    margin-left: 0px !important;
  }

  .heading_container {
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 765px) {
  .Company_name_Heading {
    width: 100%;
    overflow-wrap: anywhere;
  }

  .stackholderTable {
    margin-top: 50px;
  }

  .breadcrumb-heading {
    padding: 20px 60px;
  }
}

@media screen and (min-width: 1200px) {
  .Company_name_Heading {
    width: 100%;
    overflow-wrap: anywhere;
  }
}

@media screen and (max-width: 704px) and (min-width: 600px) {
  .dateComponent {
    margin-left: 0px;
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .businessCaseDetails {
    margin-top: -20px;
  }
}

.mainBusinessCaseContainer {
  margin: 80px 50px 20px 60px;
  width: auto;
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 30px;
}

/*antd pagination selection */
.ant-pagination-options-size-changer.ant-select .ant-select-selector {
  height: 28px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

/* 
scrollbar  */

.antd-custom-table {
  position: relative;
  overflow: hidden;
}

.ant-pagination-prev,
.ant-pagination-item,
.ant-pagination-next,
.ant-pagination-options,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  min-width: 28px !important;
  height: 28px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-options .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.antd-custom-table .ant-table-cell {
  padding: 10px 16px !important;
}

.antd-custom-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: none !important;
}

.antd-custom-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  :nth-col {
  border-right: 1px solid black;
}

.antd-custom-table:hover .antd-custom-table ::-webkit-scrollbar {
  visibility: visible;
}

.antd-custom-table ::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
  height: 8px;
  /* Set the height of the scrollbar */
  visibility: hidden;
  /* Hide the scrollbar by default */
  border-radius: 50px;
}

/* Apply the keyframes to the scrollbar thumb */
.antd-custom-table ::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  min-height: 10px;
  border-radius: 30px;
  min-width: 10px;
  animation: scrollBarThumb 2s ease-in-out infinite;
}

/* Stop the animation on hover */
.antd-custom-table ::-webkit-scrollbar-thumb :hover {
  animation-play-state: paused;
  border-radius: 30px;
}

.antd-custom-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background: transparent !important;
}

.antd-custom-table .ant-table {
  border: 1px solid #f1f4f6;
}

.antd-custom-table ::-webkit-scrollbar-track {
  display: none;
  background-color: rgba(0, 0, 0, 0.459);
  /* Set the color of the scrollbar track */
}

::-webkit-scrollbar {
  visibility: visible;
}

::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
  height: 8px;
  /* Set the height of the scrollbar */
  visibility: hidden;
  /* Hide the scrollbar by default */
  border-radius: 50px;
}

/* Apply the keyframes to the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  min-height: 10px;
  border-radius: 30px;
  min-width: 10px;
  animation: scrollBarThumb 2s ease-in-out infinite;
}

/* Stop the animation on hover */
::-webkit-scrollbar-thumb :hover {
  animation-play-state: paused;
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  display: none;
  background-color: rgba(0, 0, 0, 0.459);
  /* Set the color of the scrollbar track */
}

.table-sales-header-container {
  background: rgba(203, 212, 219, 0.263);
  border: 1px solid#f0f0f0;
}

.table-header-for-product-goal {
  padding: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #111827d5;
}

.cr-user-info-avatar {
  font-size: 24px !important;
  background-color: #fa8c16;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.breadcrumbContainer {
  margin: 0px 150px 20px 60px;
  width: auto;
}

.action-button-modified {
  color: gray !important;
  margin-right: 15px !important;
  cursor: pointer;
}

.static-img-bg {
  opacity: 0.5;
}
@media screen and (max-width: 768px) {
  .approve-for-verification {
    margin: 0px !important;
  }
}

@media screen and (max-width: 468px) {
  .company-layout {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.approve-for-verification {
  background-color: #6966c6;
  border: #494949;
  color: white;
  font-weight: 500;
  box-shadow: 1px 1px 10px rgb(212, 212, 212);
}
.approve-for-container {
  margin: 0px !important;
}
.approve-for-container-col {
  margin-bottom: 10px !important;
}

.approve-for-verification:hover {
  background-color: rgb(96, 146, 66);
  border: #494949;
  color: white;
  box-shadow: none;
}

.no-background-border {
  background: none !important;
  border: none !important;
}
